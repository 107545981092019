import React, { useEffect, useState } from "react";
import { styles } from "../../styles/index.js";
import { CustomNavbar, Footer } from "../../components";
import './styles.css';

const HomeScreen = () => {

    const [isAnnually, setIsAnnually] = useState<boolean>(false);
    const [activeAccordion, setActiveAccordion] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const packages: any = [
        {
            'id': 'Starter', 
            'billed': isAnnually ? 'Annually' : 'Monthly',
            'price': isAnnually ? 419.99 : 49.99,
            'accessed_features': [
                "Up to 5 Properties", "Maintenance Requests", "Multi-Messaging", "Rental Income Tracking"
            ]
        },
        {
            'id': 'Premium', 
            'billed': isAnnually ? 'Annually' : 'Monthly',
            'price': isAnnually ? 849.99 : 99.99,
            'accessed_features': [
                "Up to 20 Properties", "Maintenance Requests", "Multi-Messaging", "Rental Income Tracking", "24/7 Support"
            ]
        },
        {
            'id': 'Custom', 
            'billed': isAnnually ? 'Annually' : 'Monthly',
            'price': null,
            'accessed_features': [
                "All of the basic package", "All of the business package", "Your own custom features"
            ]
        }
    ];

    const toggleAccordion = (index: any) => {
        setActiveAccordion(activeAccordion === index ? null : index);
    };

   // Set up IntersectionObserver for scroll-triggered animations
    useEffect(() => {
        const sections = document.querySelectorAll('.section');
        
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                entry.target.classList.add('visible');
                }
            });
        }, {
            threshold: 0.5, // Trigger when 50% of the section is visible
        });
        
        // Observe each section
        sections.forEach(section => observer.observe(section));

        return () => {
            // Clean up observer on unmount
            observer.disconnect();
        };
    }, []);

    const handleJoinBetaClick = () => {
        setIsModalOpen(true);
    };

    // Close Modal
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleFormSubmit = () => {
        setIsFormSubmitted(true);
        setIsModalOpen(false);
    };

    return (
        <>
          <div className="font-inter antialiased bg-light bg-white text-black tracking-tight">
            <div className="flex flex-col min-h-screen overflow-hidden">
                <CustomNavbar />
                {/* Hero */}
                <section 
                    className="flex flex-col lg:flex-row items-center justify-between py-16 bg-gray-900 text-white"
                    style={styles.background}
                >
                    <div className="flex flex-col items-start lg:w-1/2 px-8 lg:px-16 mb-8 lg:mb-0 space-y-6">
                        <h1 className="text-4xl lg:text-5xl font-semibold leading-tight">
                            The <b>future</b> of property management is here
                        </h1>
                       
                        <p className="text-lg lg:text-2xl">
                            Our easy-to-use app lets you and your <span className="font-bold">tenants</span> have control, 
                            making your job as a landlord that much <span className="font-bold">easier.</span>
                        </p>

                        {isFormSubmitted ? (
                            <div className="rounded-lg">
                                <h2 className="text-2xl font-semibold text-left mb-4"><span className="text-primary">Thank you</span> for Joining!</h2>
                                <p className="text-lg text-left mb-4">You have successfully joined our waiting list. We’ll notify you when we launch!</p>
                            </div>
                        ) :(
                            <button 
                                onClick={handleJoinBetaClick}
                                className="bg-teal-500 hover:bg-teal-600 text-white text-lg font-semibold py-4 px-12 rounded-full transition-colors duration-300"
                            >
                                Get Beta Access
                            </button>
                        )}
                    </div>
                    <div className="lg:w-1/2 flex items-center justify-center lg:justify-end px-8 lg:px-16">
                        {/* You can add an image, illustration, or leave it as a placeholder */}
                        <img 
                            src={require('../../assets/images/hero-image.png')} 
                            alt="Hero" 
                            className="w-156 lg:w-156 h-auto rounded-lg"
                        />
                    </div>
                </section>

                {/* Modal */}
                {isModalOpen && !isFormSubmitted && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-white p-8 rounded-lg shadow-lg w-96">
                            <h2 className="text-2xl font-semibold text-center mb-4">Join the Waiting List</h2>
                            
                            {/* Netlify form */}
                            <form 
                                name="waitlist-form" 
                                method="POST" 
                                data-netlify="true" 
                                onSubmit={handleFormSubmit} 
                            >
                                <input 
                                    type="hidden" 
                                    name="form-name" 
                                    value="waitlist-form" 
                                />
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Enter your email"
                                    required
                                    className="w-full py-3 px-4 text-lg text-teal-600 placeholder-gray-400 focus:outline-none border border-teal-300 rounded-lg mb-4"
                                />
                                <button
                                    type="submit"
                                    className="w-full bg-teal-500 text-white py-3 px-6 text-lg font-semibold rounded-lg transition-all duration-300 hover:bg-teal-600 focus:ring-2 focus:ring-teal-600"
                                >
                                    Join Now
                                </button>
                            </form>

                            <button
                                onClick={handleCloseModal}
                                className="absolute top-2 right-2 text-lg font-semibold text-gray-600"
                            >
                                <img 
                                    alt="close" 
                                    src={require('../../assets/images/close_icon.png')} 
                                    className="w-10"
                                />
                            </button>
                        </div>
                    </div>
                )}

                {/* Trust by */}
                <section 
                    id="trustedby"
                    className="section flex flex-col items-center py-12 bg-white relative"
                >
                    <img
                        src={require('../../assets/images/trusted_by_background_image.png')}
                        alt="Background"
                        className="absolute top-0 left-0 w-full h-full object-cover object-center "
                    />
                    
                    <div className="w-full overflow-hidden relative">
                        <h1 className="text-4xl md:text-4xl text-navy text-center mb-8 px-4">
                            <b>Trusted</b> by <b></b> landlords
                        </h1>
                    </div>
                    
                    <div className="w-full overflow-hidden relative">
                        <div className="image-slider flex space-x-4 sm:space-x-6 md:space-x-8 animate-slideRight">
                        {[...Array(12)].map((_, index) => (
                            <div
                                key={index}
                                    className="w-14 h-14 sm:w-20 sm:h-20 md:w-24 md:h-24 rounded-full bg-cover bg-center aspect-[1/1]"
                                style={{
                                    backgroundImage: `url(${require(`../../assets/images/landlords/landlord_${index + 1}.png`)})`,
                                }}
                            ></div>
                        ))}
                        </div>
                    </div>
                    
                    {/* Slider - Slide Left */}
                    <div className="w-full overflow-hidden relative my-20 md:my-40">
                        <div className="image-slider flex space-x-4 sm:space-x-6 md:space-x-8 animate-slideLeft">
                            {[...Array(12)].map((_, index) => (
                                <div
                                    key={index + 12}
                                    className="w-14 h-14 sm:w-20 sm:h-20 md:w-24 md:h-24 rounded-full bg-cover bg-center aspect-[1/1]"
                                    style={{
                                        backgroundImage: `url(${require(`../../assets/images/landlords/landlord_${index + 6}.png`)})`,
                                    }}
                                ></div>
                            ))}
                        </div>
                    </div>

                </section>
                {/* Steps */}
                <section 
                    id="steps"
                    className="section flex flex-col items-center py-12 px-8 md:px-16 bg-white relative"
                >
                    <div className="text-center mb-8">
                        <h1 className="text-4xl md:text-4xl text-navy mb-4">
                            <b>Get started with Rentee in 3 easy steps</b>
                        </h1>
                        <p className="text-base md:text-lg text-navy">
                            We are building <b>Rentee</b> to be the most easy to use tool for <b>tenants</b> and <b>landlords</b>. Owning a rental property shouldn’t be complicated.
                        </p>
                    </div>
                    
                    {/* Flex container for steps and image */}
                    <div className="flex flex-col md:flex-row justify-between items-center w-full gap-8">
                        {/* Steps */}
                        <div className="flex flex-col gap-6 md:w-1/2 ">
                            {/* Step 1 */}
                            <div className="flex items-center border border-gray-100 shadow py-3 md:py-10 px-8 md:px-12 rounded-3xl">
                                <h1 className="text-navy text-5xl font-bold mr-4">1</h1>
                                <div>
                                    <h2 className="text-teal-500 text-lg md:text-2xl font-semibold">Create your account</h2>
                                    <p className=" md:text-md">Tell us a bit about yourself and your business—it’s quick and easy.</p>
                                </div>
                            </div>

                            {/* Step 2 */}
                            <div className="flex items-center border border-gray-100 shadow py-3 md:py-10 px-8 md:px-12 rounded-3xl">
                                <h1 className="text-navy text-5xl font-bold mr-4">2</h1>
                                <div>
                                    <h2 className="text-teal-500 text-lg md:text-2xl font-semibold">Add your properties</h2>
                                    <p className="text-md">Time to set up shop! Add your property details so we know what you're managing.</p>
                                </div>
                            </div>

                            {/* Step 3 */}
                            <div className="flex items-center border border-gray-100 shadow py-3 md:py-10 px-8 md:px-12 rounded-3xl">
                                <h1 className="text-navy text-5xl font-bold mr-4">3</h1>
                                <div>
                                    <h2 className="text-teal-500 text-lg md:text-2xl font-semibold">Invite your tenants</h2>
                                    <p className="text-md">Bring your tenants onboard! Share their unique tenant code and invite them to download Rentee.</p>
                                </div>
                            </div>
                        </div>

                        {/* Image on the right of all steps */}
                        <div className="w-full md:w-3/5 mt-8 md:mt-0">
                            <img
                                src={require('../../assets/images/steps_image.png')} // Add your image path here
                                alt="stages"
                                className="w-full h-auto object-cover"
                            />
                        </div>
                    </div>
                </section>

                {/* FAQ  */}
                <section id="faq" className="section py-16 px-8 pb-24 bg-white text-black">
                    <div className="text-center mb-12">
                        <h1 className="text-4xl md:text-5xl text-navy font-semibold mb-4">
                            Frequently Asked <b>Questions</b>
                        </h1>
                        <p className="text-lg md:text-xl text-gray-600">
                            Here are the most common questions landlords have when using our platform.
                        </p>
                    </div>

                    <div className="max-w-4xl mx-auto space-y-6">
                        {/* Accordion Item 1 */}
                        <div className="border-b border-gray-200 bg-white">
                            <button
                                className="w-full flex justify-between items-center text-left px-6 py-5 text-lg rounded-t-lg font-semibold bg-slate-100 hover:bg-gray-200 transition-colors duration-300 focus:outline-none"
                                onClick={() => toggleAccordion(1)}
                            >
                                How do I track rent payments?
                                <img
                                    alt="arrow down"
                                    src={require('../../assets/images/chevron_down_icon.png')}
                                    className={`w-4 md:w-8 transition-transform duration-300 ${activeAccordion === 1 ? 'rotate-180' : ''}`}
                                />
                            </button>
                            <div
                                className={`px-6 py-0 text-base text-gray-700 overflow-hidden transition-all duration-300 ease-in-out ${
                                    activeAccordion === 1 ? 'max-h-[500px] py-4' : 'max-h-0 py-0'
                                }`}
                            >
                                You can easily track rent payments through your dashboard. Payments will be added once a tenant notifies us that they have paid their rent. You will be alerted to confirm the payment, and once confirmed, the rent status will be updated. 
                            </div>
                        </div>

                        {/* Accordion Item 2 */}
                        <div className="border-b border-gray-200 bg-white">
                            <button
                                className="w-full flex justify-between items-center text-left px-6 py-5 text-lg rounded-t-lg font-semibold bg-slate-100 hover:bg-gray-200 transition-colors duration-300 focus:outline-none"
                                onClick={() => toggleAccordion(2)}
                            >
                                How do I handle late payments?
                                <img
                                    alt="arrow down"
                                    src={require('../../assets/images/chevron_down_icon.png')}
                                    className={`w-4 md:w-8 transition-transform duration-300 ${activeAccordion === 2 ? 'rotate-180' : ''}`}
                                />
                            </button>
                            <div
                                className={`px-6 py-0 text-base text-gray-700 overflow-hidden transition-all duration-300 ease-in-out ${
                                    activeAccordion === 2 ? 'max-h-[500px] py-4' : 'max-h-0 py-0'
                                }`}
                            >
                                If a tenant misses a payment, we will send them reminders until they confirm the payment. Once the tenant updates their payment status, or if they have already paid you directly, you can update their payment status accordingly.
                            </div>
                        </div>

                        {/* Accordion Item 3 */}
                        <div className="border-b border-gray-200 bg-white">
                            <button
                                className="w-full flex justify-between items-center text-left px-6 py-5 text-lg rounded-t-lg font-semibold bg-slate-100 hover:bg-gray-200 transition-colors duration-300 focus:outline-none"
                                onClick={() => toggleAccordion(3)}
                            >
                                Can I add multiple properties to my account?
                                <img
                                    alt="arrow down"
                                    src={require('../../assets/images/chevron_down_icon.png')}
                                    className={`w-4 md:w-8 transition-transform duration-300 ${activeAccordion === 3 ? 'rotate-180' : ''}`}
                                />
                            </button>
                            <div
                                className={`px-6 py-0 text-base text-gray-700 overflow-hidden transition-all duration-300 ease-in-out ${
                                    activeAccordion === 3 ? 'max-h-[500px] py-4' : 'max-h-0 py-0'
                                }`}
                            >
                                Subject to the number of properties included in your subscription plan, we offer a Starter Package, which covers up to 5 properties, and a Premium Plan, which covers up to 20 properties. If you have more properties, we recommend contacting us to create a custom package tailored to your needs.
                            </div>
                        </div>

                        {/* Accordion Item 4 */}
                        <div className="border-b border-gray-200 bg-white">
                            <button
                                className="w-full flex justify-between items-center text-left px-6 py-5 text-lg rounded-t-lg font-semibold bg-slate-100 hover:bg-gray-200 transition-colors duration-300 focus:outline-none"
                                onClick={() => toggleAccordion(4)}
                            >
                                How do maintenance requests work?
                                <img
                                    alt="arrow down"
                                    src={require('../../assets/images/chevron_down_icon.png')}
                                    className={`w-4 md:w-8  transition-transform duration-300 ${activeAccordion === 4 ? 'rotate-180' : ''}`}
                                />
                            </button>
                            <div
                                className={`px-6 py-0 text-base text-gray-700 overflow-hidden transition-all duration-300 ease-in-out ${
                                    activeAccordion === 4 ? 'max-h-[500px] py-4' : 'max-h-0 py-0'
                                }`}
                            >
                                Tenants can create maintenance requests and upload photos as proof of the issue. Once the request is submitted, we will notify you about the issue from the respective property. You can then address the issue and, once resolved, close it in the maintenance section of the app.
                            </div>
                        </div>

                        {/* Accordion Item 5 */}
                        <div className="border-b border-gray-200 bg-white">
                            <button
                                className="w-full flex justify-between items-center text-left px-6 py-5 text-lg rounded-t-lg font-semibold bg-slate-100 hover:bg-gray-200 transition-colors duration-300 focus:outline-none"
                                onClick={() => toggleAccordion(5)}
                            >
                                <p>How can I communicate with tenants?</p>
                                <img
                                    alt="arrow down"
                                    src={require('../../assets/images/chevron_down_icon.png')}
                                    className={`w-4 md:w-8 transition-transform duration-300 ${activeAccordion === 5 ? 'rotate-180' : ''}`}
                                />
                            </button>
                            <div
                                className={`px-6 py-0 text-base text-gray-700 overflow-hidden transition-all duration-300 ease-in-out ${
                                    activeAccordion === 5 ? 'max-h-[500px] py-4' : 'max-h-0 py-0'
                                }`}
                            >
                                You can easily communicate with tenants through the built-in messaging feature on the platform, ensuring that all conversations are documented for future reference and facilitating better communication. Additionally, you can send mass messages to all your tenants at once, ideal for sharing important information in case of an emergency or for any other relevant updates.
                            </div>
                        </div>
                    </div>
                </section>
                
                {/* pricing */}
                <section 
                    id="pricing"
                    className="flex flex-col items-center py-12  bg-white relative"
                >
                    <img
                        src={require('../../assets/images/pricing_background_image.png')}
                        alt="Background"
                        className="absolute top-0 left-0 w-full h-full object-cover object-center "
                    />

                    <div className="w-full relative px-8 md:px-16">
                        {/* Section Header */}
                        <div className="text-center mb-12">
                            <h1 className="text-4xl md:text-5xl text-navy mb-4 font-semibold">
                                <b>Choose the plan that’s right for you</b>
                            </h1>
                            <p className="text-base md:text-lg text-navy">
                                Take <b>control</b> of your rental properties with ease.
                            </p>
                        </div>

                        {/* Pricing Toggle (Monthly / Annually) */}
                        <div className="flex flex-col items-center mb-4">
                            {/* Toggle Slider Container */}
                            <div className="relative w-72 h-12 bg-gray-200 rounded-full flex items-center">
                                {/* Sliding Indicator */}
                                <div
                                    className={`absolute top-0 bottom-0 my-1 left-0 h-10 w-1/2 bg-white rounded-full transition-transform duration-300 ${
                                        isAnnually ? 'transform translate-x-full' : ''
                                    }`}
                                ></div>

                                {/* Monthly Button */}
                                <button
                                    onClick={() => setIsAnnually(false)}
                                    className={`z-10 w-1/2 h-full rounded-full flex items-center justify-center text-lg font-semibold ${
                                        !isAnnually ? 'text-navy' : 'text-gray-400'
                                    }`}
                                >
                                    Monthly
                                </button>

                                {/* Annually Button */}
                                <button
                                    onClick={() => setIsAnnually(true)}
                                    className={`z-10 w-1/2 h-full rounded-full flex items-center justify-center text-lg font-semibold ${
                                        isAnnually ? 'text-navy' : 'text-gray-400'
                                    }`}
                                >
                                    Annually
                                </button>
                            </div>

                            {/* Save 30% Message */}
                            <div className="h-8 flex items-center justify-center my-4">
                                {isAnnually && (
                                    <div className="px-4 py-2 bg-green-100 text-green-800 text-sm font-semibold rounded-full shadow">
                                        Save 30% when billed annually!
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Pricing Plans */}
                        <div className="flex flex-col sm:flex-row justify-center gap-6 sm:gap-4">

                            {/* Basic Plan */}
                            <div className="w-full md:w-[300px] bg-white rounded-lg shadow-lg hover:shadow-xl transition-all md:max-h-[500px] flex flex-col md:mt-5">
                                <div className="bg-green-500 text-white text-center p-4 rounded-t-lg">
                                    <h2 className="text-xl font-semibold">1 Month Free Trial</h2>
                                </div>
                                <div className="px-6 flex-grow">
                                    <div className="text-left py-1 pb-4">
                                            <h1 className="text-2xl text-navy font-extrabold py-1">{packages[0].id}</h1>
                                            <h3 className="text-3xl text-navy font-bold">£{packages[0].price}</h3>
                                            <p className="text-base text-gray-400 pt-2">per account, billed <b>{packages[0].billed}</b></p>
                                    </div>
                                    <div className="border-t border-gray-300 py-4">
                                        <ul className="text-left text-gray-700 space-y-3">

                                            {packages[0].accessed_features.map((item: string) => (
                                                <li className="flex items-center">
                                                    <span className="mr-3">
                                                        <img
                                                            src={require('../../assets/images/tick_green.png')}
                                                            alt="Background"
                                                            className="w-6"
                                                            style={{ filter: 'hue-rotate(10deg) brightness(1.1)' }}
                                                        />
                                                    </span> {item}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className="flex flex-row justify-center items-center my-6">
                                    <a href="/" className="bg-white px-16 py-2 border w-full mx-4 border-gray-200 shadow-sm rounded-full hover:bg-gray-100 text-base text-center">Choose</a>
                                </div>
                            </div>

                            {/* Business Plan */}
                            <div className="w-full md:w-[350px] bg-white rounded-lg shadow-lg hover:shadow-xl transition-all md:min-h-[600px] flex flex-col">
                                <div className="bg-navy text-white text-center p-4 rounded-t-lg">
                                    <h2 className="text-xl font-semibold">Business</h2>
                                </div>
                                <div className="px-6 flex-grow">
                                    <div className="text-left py-1 pb-4">
                                            <h1 className="text-2xl text-navy font-extrabold py-1">{packages[1].id}</h1>
                                            <h3 className="text-3xl text-navy font-bold">£{packages[1].price}</h3>
                                            <p className="text-base text-gray-400 pt-2">per account, billed <b>{packages[1].billed}</b></p>
                                    </div>
                                    <div className="border-t border-gray-300 py-4">
                                        <ul className="text-left text-gray-700 space-y-3">
                                            {packages[1].accessed_features.map((item: string) => (
                                                <li className="flex items-center">
                                                    <span className="mr-3">
                                                        <img
                                                            src={require('../../assets/images/tick_green.png')}
                                                            alt="Background"
                                                            className="w-6"
                                                            style={{ filter: 'hue-rotate(10deg) brightness(1.1)' }}
                                                        />
                                                    </span> {item}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className="flex flex-row justify-center items-center my-6">
                                    <a href="/" className="bg-white px-16 py-2 border w-full mx-4 border-gray-200 shadow-sm rounded-full hover:bg-gray-100 text-base text-center">Choose</a>
                                </div>
                            </div>

                            {/* Custom */}
                            <div className="w-full md:w-[300px] bg-white rounded-lg shadow-lg hover:shadow-xl transition-all md:max-h-[500px] flex flex-col md:mt-5">
                                <div className="bg-blue-400 text-white text-center p-4 rounded-t-lg">
                                    <h2 className="text-xl font-semibold">Enterprise</h2>
                                </div>
                                <div className="px-6 flex-grow">
                                    <div className="text-left py-1 pb-4">
                                        <h1 className="text-2xl text-navy font-extrabold py-1">{packages[2].id}</h1>
                                        <p className="text-base text-gray-400 pt-2">per account, billed <b>{packages[2].billed}</b></p>
                                    </div>
                                    <div className="border-t border-gray-300 py-4">
                                        <ul className="text-left text-gray-700 space-y-3">
                                            {packages[2].accessed_features.map((item: string) => (
                                                <li className="flex items-center">
                                                    <span className="mr-3">
                                                        <img
                                                            src={require('../../assets/images/tick_green.png')}
                                                            alt="Background"
                                                            className="w-6"
                                                            style={{ filter: 'hue-rotate(10deg) brightness(1.1)' }}
                                                        />
                                                    </span> {item}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className="flex flex-row justify-center items-center my-6">
                                    <a href="mailto:enterprise@rentee.com.au" className="bg-white px-16 py-2 border w-full mx-4 border-gray-200 shadow-sm rounded-full hover:bg-gray-100 text-base text-center">Contact Us</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                {/* join beta */}
                <section
                    id="action_section"
                    className="section flex flex-col items-center py-16 bg-teal-500 relative h-auto"
                >
                    <div className="relative z-10 text-center px-6 md:px-0">
                        {/* Heading */}
                        <h2 className="text-5xl font-extrabold text-white mb-4">Join Our Beta</h2>
                        <p className="text-lg text-white mb-8">Sign up to get early access and exclusive updates.</p>

                        {isFormSubmitted ? (
                            <div>
                                <h2 className="text-2xl font-bold text-left mb-4 text-white">Thank you for Joining!</h2>
                                <p className="text-lg text-left mb-4 text-white ">You have successfully joined our waiting list. We’ll notify you when we launch!</p>
                            </div>
                        ) : (
                            <form
                                name="waitlist-form" 
                                method="POST" 
                                data-netlify="true" 
                                onSubmit={handleFormSubmit} 
                            >
                                {/* Hidden field for Netlify */}
                                <input type="hidden" name="form-name" value="beta-form" />

                                {/* Form Fields */}
                                <div className="flex flex-col items-center bg-white rounded-lg shadow-lg overflow-hidden p-6">
                                    <input
                                        type="email"
                                        name="email"
                                        required
                                        placeholder="Enter your email"
                                        className="w-full py-4 px-6 mb-4 text-lg text-teal-600 placeholder-gray-400 focus:outline-none border border-teal-300 rounded-lg focus:ring-2 focus:ring-teal-500"
                                    />
                                    <button
                                        type="submit"
                                        className="w-full bg-navy text-white py-4 px-6 text-lg font-semibold rounded-lg transition-all duration-300 hover:bg-teal-800 focus:ring-2 focus:ring-teal-600"
                                    >
                                        Join the waiting list
                                    </button>
                                </div>
                            </form>
                        )}
                    </div>
                </section>




                <Footer/>

            </div>
          </div>
        </>
    );
}

export default HomeScreen;