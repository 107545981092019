import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export const CustomNavbar = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [thanksModal, setThanksModal] = useState<boolean>(true);

  const handleJoinBetaClick = () => {
    setIsModalOpen(true);
    setIsOpen(false);
  };

  // Close Modal
  const handleCloseModal = () => {
      setIsModalOpen(false);
  };

  const handleFormSubmit = () => {
      setIsFormSubmitted(true);
      setIsModalOpen(false);
  };

  return (
    <>
      <nav className="bg-[#1B1D25] text-white ">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-2">
          <div className="flex items-center justify-between h-auto">
            {/* Logo */}
            <div className="flex items-center">
              <img
                src={require('../../assets/images/logo.png')}
                alt="Logo"
                className="w-[200px] h-auto pb-5"
              />
            </div>

            {/* Desktop Menu */}

            {/* coming soon nav */}
            <div className="hidden md:flex md:items-center md:space-x-12">
              
              <a href="/#steps" className="relative text-white hover:text-gray-200 py-2 transition-all before:content-[''] before:absolute before:bottom-0 before:left-1/2 before:w-0 before:h-[2px] before:bg-white before:transition-all before:duration-300 hover:before:left-0 hover:before:w-full">
                How it works
              </a>
              <a href="/#features"  className="relative text-white hover:text-gray-200 py-2 transition-all before:content-[''] before:absolute before:bottom-0 before:left-1/2 before:w-0 before:h-[2px] before:bg-white before:transition-all before:duration-300 hover:before:left-0 hover:before:w-full">
                Features
              </a>
              <a href="/#pricing" className="relative text-white hover:text-gray-200 py-2 transition-all before:content-[''] before:absolute before:bottom-0 before:left-1/2 before:w-0 before:h-[2px] before:bg-white before:transition-all before:duration-300 hover:before:left-0 hover:before:w-full">
                Pricing
              </a>
              <button onClick={handleJoinBetaClick} className="text-white py-3 px-8 rounded-full bg-teal-500 hover:bg-teal-600 ">
                <span className="flex items-center space-x-2">
                  Get Beta Access
                </span>
              </button>
            </div>

            {/* Mobile Menu Button */}
            <div className="md:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="text-white focus:outline-none"
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {isOpen ? (
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  ) : (
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  )}
                </svg>
              </button>
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        <div
          className={`fixed top-0 left-0 w-full h-full bg-white z-50 transition-transform duration-300 ease-in-out transform ${
            isOpen ? 'translate-x-0' : '-translate-x-full'
          }`}
        >
          {/* Close Button */}
          <div className="flex justify-end px-4 pt-4">
            <button
              onClick={() => setIsOpen(false)}
              className="text-black focus:outline-none"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          {/* Mobile Menu Links */}
          <div className="w-full bg-white h-full px-4 pt-2 pb-3 space-y-4 absolute left-0">
            
            <a
              href="#steps"
              onClick={() => setIsOpen(false)}
              className="block text-black hover:text-gray-300 px-3 py-2 rounded-md"
            >
              How it works
            </a>
            <a
              href="#features"
              onClick={() => setIsOpen(false)}
              className="block text-black hover:text-gray-300 px-3 py-2 rounded-md"
            >
              Features
            </a>
            <a
              href="#pricing"
              onClick={() => setIsOpen(false)}
              className="block text-black hover:text-gray-300 px-3 py-2 rounded-md"
            >
              pricing
            </a>
            <Link
              to="/"
              onClick={handleJoinBetaClick}
              className="block bg-teal-500 hover:bg-teal-600 text-white py-3 px-8 rounded-full text-center"
            >
              Get Beta Access
            </Link>
          </div>
        </div>
      </nav>
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg w-96">
              <h2 className="text-2xl font-semibold text-center mb-4">Join the Waitlist</h2>
              {/* Netlify form */}
              <form 
                  name="waitlist-form" 
                  method="POST" 
                  data-netlify="true" 
                  onSubmit={handleFormSubmit} 
              >
                  <input 
                      type="hidden" 
                      name="form-name" 
                      value="waitlist-form" 
                  />
                  <input
                      type="email"
                      name="email"
                      placeholder="Enter your email"
                      required
                      className="w-full py-3 px-4 text-lg text-black placeholder-gray-400 focus:outline-none border border-teal-300 rounded-lg mb-4"
                  />
                  <button
                      type="submit"
                      className="w-full bg-teal-500 text-white py-3 px-6 text-lg font-semibold rounded-lg transition-all duration-300 hover:bg-teal-600 focus:ring-2 focus:ring-teal-600"
                  >
                      Join Now
                  </button>
              </form>

              <button
                  onClick={handleCloseModal}
                  className="absolute top-2 right-2 text-lg font-semibold text-gray-600"
              >
                  <img 
                      alt="close" 
                      src={require('../../assets/images/close_icon.png')} 
                      className="w-10"
                  />
              </button>
          </div>
        </div>
      )}

      {isFormSubmitted && thanksModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white p-8 rounded-lg shadow-lg w-96">
                  <h2 className="text-2xl font-semibold text-center mb-4">Thank You for Joining!</h2>
                  <p className="text-lg text-center mb-4">You have successfully joined our waitlist. We’ll see you soon!</p>
                  <button
                      onClick={() => setThanksModal(false)}
                      className="w-full bg-teal-500 text-white py-3 px-6 text-lg font-semibold rounded-lg transition-all duration-300 hover:bg-teal-600"
                  >
                      Close
                  </button>
              </div>
          </div>
      )}
    </>
  );
};
