import React, { useState } from "react";
import { FaInstagram, FaFacebook, FaTiktok, FaLinkedin } from 'react-icons/fa';

export const Footer = () => {
  const currentYear = new Date().getFullYear();
  const [selectedLanguage, setSelectedLanguage] = useState("English");

  const handleLanguageChange = (e: any) => {
    setSelectedLanguage(e.target.value);
  };

  return (
    <>
      <div className="bg-navy text-white">
        <div className="pt-8" id="footer">
          
            {/* Social Media Follow Section */}
            <div className="flex justify-center items-center mb-10">
                <div className="text-center">
                <h3 className="text-white text-xl font-semibold">Follow us on:</h3>
                <div className="flex justify-center mt-4 space-x-6">
                    <a href="https://www.instagram.com/renteeapp" target="_blank" className="text-white hover:text-gray-300" rel="noreferrer">
                        <FaInstagram size={30} />
                    </a>
                    {/* <a href="https://www.reddit.com/in/renteeapp" target="_blank" className="text-white hover:text-gray-300" rel="noreferrer">
                        <FaReddit size={30} />
                    </a> */}
                    <a href="https://www.facebook.com/renteeapp" target="_blank" className="text-white hover:text-gray-300" rel="noreferrer">
                        <FaFacebook size={30} />
                    </a>
                    <a href="https://www.tiktok.com/@renteeapp" target="_blank" className="text-white hover:text-gray-300" rel="noreferrer">
                        <FaTiktok size={30} />
                    </a>
                    <a href="https://www.linkedin.com/company/renteeapp/" target="_blank" className="text-white hover:text-gray-300" rel="noreferrer">
                        <FaLinkedin size={30} />
                    </a>
                </div>
                </div>
            </div>

            {/* Footer Links Section */}
            <div className="md:hidden container mx-auto px-4">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 md:text-center ml-10">
                    <div>
                        <h3 className="text-white text-xl font-semibold">Overview</h3>
                        <ul className="space-y-2 mt-4">
                        <li>
                            <a href="/#features" className="text-white text-base font-normal hover:text-gray-300">Features</a>
                        </li>
                        <li>
                            <a href="/#steps" className="text-white text-base font-normal hover:text-gray-300">How it works</a>
                        </li>
                        <li>
                            <a href="/pricing" className="text-white text-base font-normal hover:text-gray-300">Pricing</a>
                        </li>
                        </ul>
                    </div>
                    {/* <div>
                        <h3 className="text-white text-xl font-semibold">Resources</h3>
                        <ul className="space-y-2 mt-4">
                        <li>
                            <a href="/" className="text-white text-base font-normal hover:text-gray-300">Help Center</a>
                        </li>
                        <li>
                            <a href="/" className="text-white text-base font-normal hover:text-gray-300">What's new</a>
                        </li>
                        <li>
                            <a href="/" className="text-white text-base font-normal hover:text-gray-300">FAQ's</a>
                        </li>
                        </ul>
                    </div> */}
                    {/* <div>
                        <h3 className="text-white text-xl font-semibold">Company</h3>
                        <ul className="space-y-2 mt-4">
                        <li>
                            <a href="/about-us/" className="text-white text-base font-normal hover:text-gray-300">About Us</a>
                        </li>
                        <li>
                            <a href="mailto:info@rentee.com.au" className="text-white text-base font-normal hover:text-gray-300">Careers</a>
                        </li>
                        <li>
                            <a href="mailto:press@rentee.com.au" className="text-white text-base font-normal hover:text-gray-300">Press</a>
                        </li>
                        </ul>
                    </div> */}
                </div>
            </div>
          
            {/* New Bottom Section with Logo, Links, and Language Dropdown */}
            <div className="flex flex-col sm:flex-row sm:justify-between items-center mt-8 px-4 sm:px-14 border-t border-gray-700 pt-6">
                {/* Logo */}
                <div className="flex items-center space-x-4 mb-4 sm:mb-0">
                    <img
                        src={require('../../assets/images/logo.png')}
                        alt="Logo"
                        className="w-[150px]"
                    />
                </div>
                
                {/* Links */}
                {/* <div className="flex flex-wrap justify-center sm:justify-start space-x-4 text-white mb-4 sm:mb-0">
                    <a href="/terms-of-service" className="text-base font-normal hover:text-gray-300">Terms of Service</a>
                    <a href="/user-agreement" className="text-base font-normal hover:text-gray-300">Privacy Policy</a>
                    <a href="/terms-of-service" className="text-base font-normal hover:text-gray-300">End User License Agreement</a>
                    <a href="/terms-of-service" className="text-base font-normal hover:text-gray-300">Acceptable Use Policy</a>
                    <a href="/terms-of-service" className="text-base font-normal hover:text-gray-300">Disclaimer</a>
                </div> */}

                {/* Language Dropdown */}
                <div className="flex items-center space-x-4">
                    <select
                    value={selectedLanguage}
                    onChange={handleLanguageChange}
                        className="bg-gray-800 text-white px-4 py-3 rounded-lg"
                    >
                        <option value="English">English</option>
                    </select>
                </div>
            </div>

          {/* Copyright Section */}
            <div className="flex justify-center items-center mt-8 px-14 pb-3">
                <p className="text-sm text-gray-300"><b>Rentee</b> © {currentYear}. All rights reserved</p>
            </div>
            </div>
        </div>
    </>
  );
};
