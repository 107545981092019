import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomeScreen from './screens/HomeScreen/HomeScreen.tsx';
import NotFoundScreen from './screens/NotFoundScreen/NotFoundScreen.tsx';

function App() {
  return (
    <Router>
      <Routes>
          <Route path="/" element={ <HomeScreen /> } />
          <Route path="*" element={<NotFoundScreen />} /> 
        </Routes>
    </Router>
  );
}

export default App;
